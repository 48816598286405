import React from 'react'
import { Bling as Ads } from 'react-gpt'
import { Link } from 'gatsby'
import Image from 'react-image'
import { Fade } from 'react-reveal'
import Sticky from 'react-stickynode'
import TimeAgo from 'react-timeago'
import classNames from 'classnames'

import { RowLayout } from '../../components/layout'

import { getStationDataByID } from '../../networking.js'
import {
  LeaderboardAd,
  LargeRectangleAd,
  MediumRectangleAd,
  MobileAd
} from '../../ads.js'
import Page from '../templates/Page'
import PageSection from '../templates/PageSection'
import PlayButton from '../components/PlayButton'
import ActionButton from '../components/ActionButton'

import './station.css'

class Station extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSessionActive: this.isSessionActive(),
      stationData: null
    }
  }
  componentDidMount() {
    if (!this.state.isSessionActive) {
      this.loadData()
    }
    this.adsRefreshIntervalID = setInterval(function() {
      Ads.refresh()
      console.log('station: refresh ads')
    }, 30000)
  }
  componentDidUpdate(prevProps) {
    if (!this.dataRefreshIntervalID && !this.state.isSessionActive) {
      this.dataRefreshIntervalID = setInterval(
        function() {
          this.loadData()
        }.bind(this),
        3000
      )
    } else if (!this.state.isSessionActive && this.isSessionActive()) {
      this.setState({
        isSessionActive: true
      })
      clearInterval(this.dataRefreshIntervalID)
    }
  }
  componentWillUnmount() {
    clearInterval(this.dataRefreshIntervalID)
    clearInterval(this.adsRefreshIntervalID)
  }
  loadData() {
    // console.log('not active session: refreshing station data')
    getStationDataByID(this.props.stationData.mount_id, data => {
      this.setState({ stationData: data })
    })
  }
  isSessionActive() {
    if (this.props.session.metadata) {
      return (
        this.props.session.metadata['mount-id'] ===
        this.props.stationData.mount_id
      )
    } else {
      return this.props.session.stationID === this.props.stationData.mount_id
    }
  }
  mergeData() {
    const pageData = this.props.stationData
    const sessionData = this.props.session.metadata
    if (sessionData && !this.isSessionActive()) {
      // prevent old session cover image from flashing before new one loads
      delete sessionData.cover
    }
    return Object.assign(
      this.state.stationData || {},
      this.isSessionActive() && sessionData ? sessionData : {},
      pageData
    )
  }
  render() {
    const data = this.mergeData()
    return (
      <Page
        className={classNames('station')}
        header={
          <StationHeader
            className={classNames('station')}
            playerSession={this.props.session}
            updatePlayerSession={this.props.updateSession}
            stationID={data.mount_id}
            stationURL={data.fields.url}
            name={data.station_name}
            logo={data.logo}
            genres={data['genres'] || null}
            cover={data['cover'] || null}
            currentTrack={data['current-track'] || null}
            externalPlayer={data['player_url'] || null}
          />
        }
      >
        <PageSection>
          <RowLayout
            isResponsive
            itemSpacing={'4vw'}
            style={{ alignItems: 'flex-start', padding: '2rem 0' }}
          >
            <div className={classNames('recently-played', 'column')}>
              {typeof window !== 'undefined' && window.innerWidth > 1200 && (
                <LeaderboardAd />
              )}

              <h1>Recently Played</h1>
              {data && (
                <ul className={classNames('recently-played-list')}>
                  {data['last-played'] &&
                    data['last-played'].map((track, i) => (
                      <li key={i} className={classNames('track-item')}>
                        <img src={track['art']} alt={track['title']} />
                        <div className={classNames('column')}>
                          <p className={classNames('track-title')}>
                            {track['title']}
                          </p>
                          <p className={classNames('track-artist')}>
                            {track['artist']}
                          </p>
                        </div>
                        <TimeAgo
                          className={classNames('track-played-time')}
                          date={track['start']}
                        />
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className={classNames('station', 'sidebar')}>
              {typeof window !== 'undefined' && window.innerWidth > 768 ? (
                <MediumRectangleAd />
              ) : (
                <MobileAd />
              )}

              <div className={classNames('sidebar-panel', 'column')}>
                <h3 className={classNames('sidebar-heading')}>About</h3>

                {data && (
                  <ul className="social-media-links">
                    {data['website'] && data['website'].length > 0 && (
                      <li>
                        <a
                          href={data['website']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                          >
                            <path
                              d="M4.706 5.86L3.31 7.26a1.07 1.07 0 0 1-1.534 0c-.43-.432-.43-1.1.002-1.532L3.175 4.33l-.866-.867c-.467.466-.933.93-1.398 1.397A2.316 2.316 0 0 0 .91 8.124c.897.897 2.37.897 3.266 0l1.397-1.397zM4.36 3.143L5.76 1.747a1.07 1.07 0 0 1 1.533 0c.432.43.43 1.1 0 1.53L5.89 4.676l.867.866 1.395-1.396A2.316 2.316 0 0 0 8.157.88a2.318 2.318 0 0 0-3.266 0L3.496 2.277z"
                              fillRule="evenodd"
                              fill="#FFF"
                            />
                            <path
                              d="M6.47 1.982a.6.6 0 0 0-.41.18L2.15 6.07A.6.6 0 1 0 3 6.916L6.906 3.01a.6.6 0 0 0-.435-1.028z"
                              fillRule="evenodd"
                              fill="#FFF"
                            />
                          </svg>
                        </a>
                      </li>
                    )}

                    {data['facebook'] && data['facebook'].length > 0 && (
                      <li>
                        <a
                          href={data['facebook']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            width="6"
                            height="11"
                            viewBox="0 0 6 11"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.70658123,3.25071687 L2.13123382,3.25071687 L2.13123382,2.20048527 C2.13123382,1.80039704 2.38128897,1.72538049 2.58133308,1.72538049 L3.70658123,1.72538049 L3.70658123,0 L2.13123382,0 C0.430858846,0 0.0307706163,1.27528123 0.0307706163,2.10046321 L0.0307706163,3.25071687 L-0.944444444,3.25071687 L-0.944444444,5.00110288 L0.0307706163,5.00110288 L0.0307706163,10.0022058 L2.13123382,10.0022058 L2.13123382,5.00110288 L3.53154263,5.00110288 L3.70658123,3.25071687 Z"
                              transform="translate(1.89 .333)"
                              fillRule="nonzero"
                              fill="#FFF"
                              stroke="none"
                              strokeWidth="1"
                            />
                          </svg>
                        </a>
                      </li>
                    )}

                    {data['twitter'] && data['twitter'].length > 0 && (
                      <li>
                        <a
                          href={data['twitter']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            width="11"
                            height="9"
                            viewBox="0 0 11 9"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1153613,0.940875144 C9.74368685,1.10046247 9.34351347,1.20838463 8.92374705,1.25660523 C9.35241941,1.00861371 9.68134525,0.615386373 9.83630858,0.146957901 C9.43494791,0.376579709 8.9914323,0.543629607 8.51763658,0.633182057 C8.14002488,0.243399092 7.60032504,0 7.00303382,0 C5.85773047,0 4.92854456,0.898395233 4.92854456,2.00632028 C4.92854456,2.16361129 4.94635644,2.31630968 4.98198022,2.46326758 C3.25719732,2.37945561 1.72834519,1.58151997 0.70416254,0.366246795 C0.525450238,0.663606968 0.423328712,1.00861371 0.423328712,1.37600856 C0.423328712,2.07176253 0.789659681,2.68600084 1.34657733,3.04593292 C1.0069644,3.03617404 0.686350653,2.9448993 0.406110681,2.79564509 L0.406110681,2.82032952 C0.406110681,3.79277783 1.12155417,4.60391672 2.07152062,4.78761405 C1.8975579,4.83468659 1.71409559,4.85822276 1.5246962,4.85822276 C1.39110705,4.85822276 1.26048676,4.84616776 1.1340224,4.82263139 C1.39823185,5.61941898 2.16414235,6.19978801 3.07254773,6.21528758 C2.36244768,6.75375053 1.46710439,7.07464707 0.495169904,7.07464707 C0.327738337,7.07464707 0.162087917,7.06546221 0,7.0470925 C0.918498827,7.61540634 2.00977266,7.94778385 3.18119998,7.94778385 C6.99887767,7.94778385 9.08583518,4.89094398 9.08583518,2.23938645 C9.08583518,2.15213012 9.08405403,2.06487378 9.08049153,1.97933973 C9.48600856,1.69633087 9.83808973,1.34328734 10.1153613,0.940875144"
                              transform="translate(.76 .934)"
                              fillRule="nonzero"
                              fill="#FFF"
                              stroke="none"
                              strokeWidth="1"
                            />
                          </svg>
                        </a>
                      </li>
                    )}

                    {data['instagram'] && data['instagram'].length > 0 && (
                      <li>
                        <a
                          href={data['instagram']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.11751654,0 L9.36439896,0 C11.0861558,0 12.4819155,1.39550184 12.4819155,3.11751654 L12.4819155,9.36439896 C12.4819155,11.0861558 11.0864137,12.4819155 9.36439896,12.4819155 L3.11751654,12.4819155 C1.3957597,12.4819155 -1.13686838e-13,11.0864137 -1.13686838e-13,9.36439896 L-1.13686838e-13,3.11751654 C-1.13686838e-13,1.3957597 1.39550184,0 3.11751654,0 Z M1.24819155,9.36439896 C1.24819155,10.3969515 2.08501184,11.233724 3.11751654,11.233724 L9.36439896,11.233724 C10.3969515,11.233724 11.233724,10.3969037 11.233724,9.36439896 L11.233724,3.11751654 C11.233724,2.08496403 10.3969037,1.24819155 9.36439896,1.24819155 L3.11751654,1.24819155 C2.08496403,1.24819155 1.24819155,2.08501184 1.24819155,3.11751654 L1.24819155,9.36439896 Z M3.12047888,6.24095775 C3.12047888,4.51756486 4.51756486,3.12047888 6.24095775,3.12047888 C7.96435065,3.12047888 9.36143663,4.51756486 9.36143663,6.24095775 C9.36143663,7.96435065 7.96435065,9.36143663 6.24095775,9.36143663 C4.51756486,9.36143663 3.12047888,7.96435065 3.12047888,6.24095775 Z M8.11324508,6.24095775 C8.11324508,5.20692202 7.27499349,4.36867043 6.24095775,4.36867043 C5.20692202,4.36867043 4.36867043,5.20692202 4.36867043,6.24095775 C4.36867043,7.27499349 5.20692202,8.11324508 6.24095775,8.11324508 C7.27499349,8.11324508 8.11324508,7.27499349 8.11324508,6.24095775 Z M9.36143663,3.74457465 C9.70611521,3.74457465 9.98553241,3.46515746 9.98553241,3.12047888 C9.98553241,2.7758003 9.70611521,2.4963831 9.36143663,2.4963831 C9.01675805,2.4963831 8.73734086,2.7758003 8.73734086,3.12047888 C8.73734086,3.46515746 9.01675805,3.74457465 9.36143663,3.74457465 Z"
                              transform="translate(.22 .097)"
                              fillRule="nonzero"
                              fill="#FFF"
                              stroke="none"
                              strokeWidth="1"
                            />
                          </svg>
                        </a>
                      </li>
                    )}
                  </ul>
                )}

                {data && (
                  <p className={classNames('station-description')}>
                    {data['description']}
                  </p>
                )}
              </div>

              {typeof window !== 'undefined' && window.innerWidth > 768 && (
                <Sticky bottomBoundary=".station.page-section">
                  <LargeRectangleAd />
                </Sticky>
              )}
            </div>
          </RowLayout>
        </PageSection>
      </Page>
    )
  }
}

class StationHeader extends React.Component {
  render() {
    return (
      <header
        className={classNames(
          'page-header',
          'station-header',
          'with-background-image',
          'light-on-dark',
          this.props.className
        )}
      >
        <div
          className="station-background-image"
          style={{
            backgroundImage:
              `url(${this.props.cover})` ||
              'url(../assets/old-images/listen/header-bg@2x.png)'
          }}
        />

        <Fade duration={500}>
          <RowLayout
            isResponsive
            alignItems={'center'}
            itemSpacing={'4vw'}
            className={classNames('foreground', 'alignment-container')}
            style={{ width: 'unset' }}
          >
            {this.props.icpURL ? (
              <Link to={this.props.icpURL}>
                <Image
                  className={classNames('station-logo')}
                  src={this.props.logo}
                  alt={this.props.name}
                />
              </Link>
            ) : (
              <Image
                className={classNames('station-logo')}
                src={this.props.logo}
                alt={this.props.name}
              />
            )}

            <div className={classNames('column', 'station-data')}>
              <h1
                className={classNames('page-title', 'station-name')}
                style={{ marginTop: '0' }}
              >
                {this.props.name}
              </h1>
              {this.props.genres && (
                <ul className="station-genres">
                  {this.props.genres.map((genre, i) => (
                    <li key={i}>{genre}</li>
                  ))}
                </ul>
              )}

              {this.props.currentTrack && (
                <div className={classNames('now-playing')}>
                  {!this.props.externalPlayer ? (
                    <PlayButton
                      stationID={this.props.stationID}
                      stationURL={this.props.stationURL}
                      playerSession={this.props.playerSession}
                      updatePlayerSession={this.props.updatePlayerSession}
                    />
                  ) : (
                    <ActionButton
                      title="Listen"
                      icon={playIcon}
                      color="blue"
                      link={this.props.externalPlayer}
                    />
                  )}

                  <h2 className={classNames('now-playing-heading')}>
                    Now Playing
                  </h2>
                  <div className={classNames('track-item')}>
                    <img
                      src={this.props.currentTrack['art']}
                      alt={this.props.currentTrack['title']}
                    />
                    <div className={classNames('column')}>
                      <p className={classNames('track-title')}>
                        {this.props.currentTrack['title']}
                      </p>
                      <p className={classNames('track-artist')}>
                        {this.props.currentTrack['artist']}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </RowLayout>
        </Fade>
      </header>
    )
  }
}

const playIcon = (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0L10.5 6 0 12"
      fill="#ffffff"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
    />
  </svg>
)

export default Station
