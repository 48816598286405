// external
import React from 'react'

// internal
import SEO from '../components/SEO'
import PlayerContextConsumer from '../components/state/PlayerContext'
import Footer from '../components/Footer'

import StationPageContent from '../old/pages/Station'

// implemented as a class so lifecycle hooks can fetch dynamic data
export default class StationPage extends React.Component {
  render() {
    if (!this.props.pageContext) {
      console.log('invalid page context:', this.props.pageContext)
      return null
    }
    const { data } = this.props.pageContext
    // console.log('station page:', data)
    return (
      <React.Fragment>
        <SEO
          title={`${data.station_name} - Free Internet Radio - Live365`}
          ignoreTitleTemplate={true}
          description={data.description}
          image={data.logo}
          isRawImageURL={true}
          pathname={data.fields.url}
          scripts={[{ src: '//biddr.brealtime.com/92516381-1202.js' }]}
        />
        <PlayerContextConsumer>
          {({ session, updateSession }) => (
            <StationPageContent
              stationData={data}
              session={session}
              updateSession={updateSession}
            />
          )}
        </PlayerContextConsumer>
        <Footer />
      </React.Fragment>
    )
  }
}
